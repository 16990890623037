import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

const login = () => import("../views/login.vue");
const home = () => import("../views/Home.vue");
const qian = () => import("../views/partition/qian.vue");
const chou = () => import("../views/partition/chou.vue");
const qing = () => import("../views/partition/qing.vue");
const sure = () => import("../views/partition/sure.vue");
const pingOne = () => import("../views/partition/p1.vue");
const pingTwo = () => import("../views/partition/p2.vue");
const pingThree = () => import("../views/partition/p3.vue");
const gift = () => import("../views/partition/gift.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    key: 0,
    component: login,
  },
  {
    path: "/gift",
    name: "gift",
    key: 5,
    component: gift,
  },
  {
    path: "/home",
    name: "home",
    key: 6,
    component: home,
  },
  {
    path: "/qian",
    name: "qian",
    key: 1,
    component: qian,
  },
  {
    key: 2,
    path: "/chou",
    name: "chou",
    component: chou,
  },
  {
    key: 3,
    path: "/qing",
    name: "qing",
    component: qing,
  },
  {
    key: 4,
    path: "/sure",
    name: "sure",
    component: sure,
  },
  {
    key: "",
    path: "/pingOne",
    name: "pingOne",
    component: pingOne,
  },
  {
    key: "",
    path: "/pingTwo",
    name: "pingTwo",
    component: pingTwo,
  },
  {
    key: "",
    path: "/pingThree",
    name: "pingThree",
    component: pingThree,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  let job = localStorage.getItem("job");
  if (to.path == "/") return next();
  if(job == null || job == '') return next('/')
  if(job == "1" && to.path != '/qian') return next('/qian')
  if(job == "2" && to.path != '/chou') return next('/chou');
  if(job == "3" && to.path != '/qing') return next('/qing');
  if(job == "4" && to.path != '/sure') return next('/sure');
  if(job == "5" && to.path != '/gift') return next('/gift');
  next()
});

export default router;
