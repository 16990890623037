<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  created(){
   let login=sessionStorage.getItem('login');
  if(!login){
    this.$router.push({path:'/'});
  }
  }
}
</script>

<style>

</style>
