import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './plugins/element.js'
import './assets/css/global.css'
import http  from "../src/utils/https";
import Print from 'vue-print-nb'
import Element from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import './theme/index.css';
import animated from "./assets/css/animate.min.css";
Vue.use(animated)

Vue.use(Element)

window.$baseUrl = 'https://cq.wezhijin.com/index/'

window.$success = (msg = '提交成功!') => {
    Element.Message({
        message: msg,
        duration: 1200,
        type: 'success'
    });
  
  }
window.$error = (msg = '提交失败') => {
    Element.Message({
        message: msg,
        duration: 1200,
        type: 'error'
    });
  }
Vue.use(Print);  //注册

Vue.config.productionTip = false
Vue.prototype.$http = http;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
