import axios from "axios";
import router from '../router/index'

let burl
let url = window.location.href

console.log(url)

if(url.indexOf('192') != -1){
  burl = 'https://'+window.location.hostname+':90'
}else{
  burl = "https://cq.wezhijin.com/index/"
}

console.log(burl)

const http = axios.create({
  baseURL: burl,
});

//添加请求拦截器
http.interceptors.request.use(
  function(config) {
    let  uin = localStorage.getItem("uin");
    // console.log("config", config);    
      if (uin != null) {
        config.headers.useruid = uin;
      }
      return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use((res) => {
  if (res.data.code != 0) {
    if(res.config.url == '/index/sign_info' || res.config.url == '/index/get_house_type') return res
    
    $error(res.data.msg)

    if(res.data.code == 10086){
      localStorage.clear()
      router.push({path:'/'})
      return $error('uin失效');
    }
    return res;

  } else if (res.data.code == 0) {

    return res;
    
  }
});



export default http;
