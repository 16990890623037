<template>
  <div class="home">
      <div class="handle">
        <div class="h-img"><img src="@/assets/img/shou1_02.png" alt=""></div>
        <div class="user">
          <span>{{username}}</span>
          <!-- <i class="el-icon-arrow-down"></i> -->
          <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <i class="el-icon-arrow-down"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="back">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
          </div>
      </div>
      <div>
        <div class="h-tip">小提示 : 点击不同选区，进入板块，即可完成操作</div>
        <div class="tabs">
          <div class="tab animated bounceIn" @click="toTab(0)">
            <div class="t-top">01</div>
            <div class="t-title">签到区</div>
            <div class="t-tip">业主刷身份证签到， 派发身份标识</div>
          </div>
          <div class="tab1 animated bounceIn" @click="toTab(1)">
            <div class="t-top">02</div>
            <div class="t-title">抽签区</div>
            <div class="t-tip">业主按顺序上台抽签，根据抽签序号搜索录入抽签房屋套数</div>
          </div>
          <div class="tab animated bounceIn" @click="toTab(2)">
            <div class="t-top">03</div>
            <div class="t-title">房屋清点区</div>
            <div class="t-tip">根据抽签序号搜索，填写抽签房屋信息并且完成确认</div>
          </div>
          <div class="tab1 animated bounceIn" @click="toTab(3)">
            <div class="t-top">04</div>
            <div class="t-title">选房确认区</div>
            <div class="t-tip">根据抽签序号搜索，生成并打印选房确认书</div>
          </div>
          <div class="tab animated bounceIn" @click="toTab(4)">
            <div class="t-top">05</div>
            <div class="t-title">礼品区</div>
            <div class="t-tip">根据抽签序号搜索，录入礼品信息</div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  name: 'Home',
  data(){
    return{
      tagRouter:['/qian','/chou','/qing','/sure','/gift'],
      username:'',
      taihao:'',
    }
  },
  methods:{
    toPing(item){
      this.$router.push({path:item})
    },
    toTab(i){
      // console.log(i)
      this.$router.push({path:this.tagRouter[i]})
    },
    back(){
      this.$router.push({path:'/'})
      localStorage.clear();
    }
  },
  created(){
    this.username = localStorage.getItem('name')
    this.taihao = localStorage.getItem('taihao')
  }
}
</script>
<style lang="less" scoped>
@font-face {
  font-family: 'webfont';
  font-display: swap;
  src: url('//at.alicdn.com/t/webfont_n0jzm7bq1vl.eot'); /* IE9*/
  src: url('//at.alicdn.com/t/webfont_n0jzm7bq1vl.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('//at.alicdn.com/t/webfont_n0jzm7bq1vl.woff2') format('woff2'),
  url('//at.alicdn.com/t/webfont_n0jzm7bq1vl.woff') format('woff'), /* chrome、firefox */
  url('//at.alicdn.com/t/webfont_n0jzm7bq1vl.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url('//at.alicdn.com/t/webfont_n0jzm7bq1vl.svg#NotoSansHans-Black') format('svg'); /* iOS 4.1- */
}
.ft{
  font-family: "webfont" !important;
}
.handle{
  position: relative;
  height: 60px;
  line-height: 60px;
  padding:0 30px;
  color: #fff;
  font-size: 24px;
  .user{
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(0,-50%);
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    color: gold;
    img{
      width: 36px;
      height: 36px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 10px;
    }
    span{
      margin-right: 15px;
    }
  }
}
.h-tip{
  color: #fff !important;
  text-align: center;
  font-size: 14px;
  margin: 20px 0;
  font-weight: bold;
  animation: myAnimation 1s infinite
}

.tabs{
  background: url(../assets/img/back.png) no-repeat;
  background-size:100% 100%; 
  width:100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  position: fixed;
  z-index: -1;
  left:0;
  right:0;
  top:0;
  bottom:0;
  .tab{
    width: 14%;
    height: 0;
    padding:5% 0px 17% 0px;
    background: linear-gradient(to top, rgba(231, 200, 22, 0), rgb(2243, 167, 6) 90%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .tab1{
    margin-top: 15rem;
    width: 14%;
    height: 0;
    padding:5% 0px 17% 0px;
    background: linear-gradient(to top, rgba(231, 200, 22, 0), rgb(2243, 167, 6) 90%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .t-top{
    color: #fff;
     font-size: 3rem;
     font-weight: bolder;
     text-align: center;
     margin-bottom: 2rem;
     border-bottom: 1px solid #fff;
  }
  .t-title{
    color: #fff;
     font-size: 1.3rem;
     font-weight: bold;
     padding-bottom: 10px;
     text-align: center;
     font-weight: bold;
  }
  .t-tip{
     width: 80%;
     text-align: center;
     font-size: 87.5%;
     color: #fff;
     line-height: 20px;
     margin-top: 5px;
  }
}
@keyframes myAnimation {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}
/deep/.el-card{
  // #e1f7ff
  background: #b5011d !important;
}
.ping{
  position: absolute;
  bottom: 0;
  right: 200px;
  display: flex;
}
.p-btn{
    font-size: 14px;
  background: #fff;
  padding: 5px 10px;
  color:#666;
  height: 20px;
  line-height: 20px;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
  margin-right: 30px;
}
.p-btn:hover{
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1)
}
.h-img{
  line-height: 0;
  height: 100%;
  overflow: hidden;
  img{
    height: 100%;
    margin-top: 4px;
  }
}
</style>
